const scopeIdRegexp = /^\/foretag\/mybusiness\/(\w+)(.*)$/;

export function getScopeFromUrl(url: string): string | null {
  const scopeIdCandidate = scopeIdRegexp.exec(url)?.[1] || null;

  return checkScopeId(scopeIdCandidate) ? scopeIdCandidate : null;
}

/**
 * Seems we only have Access profile Id and TSCID as scope Id.
 * TODO: The below check should be updated and tightened accordingly.
 *
 * @param scopeIdCandidate
 */
export function checkScopeId(scopeIdCandidate: string | null): boolean {
  return /^o?[A-Z0-9]+$/.test(scopeIdCandidate || "");
}

/**
 * Access profile Id:
 * starts with 8 + eight any digits
 *
 * total length is 9
 * @param scopeIdCandidate
 */
export function checkAccessProfileId(scopeIdCandidate: string | null): boolean {
  return /^(8\d{8})$/.test(scopeIdCandidate || "");
}

/**
 * TSCID has 2 formats:
 * starts with 7 + nine any digits
 * starts with 00 + eight uppercase letters or digits
 *
 * total length is 10
 * NOTE: we are supporting between 4 to 10 total length currently, due to older tests
 * @param scopeIdCandidate
 */
export function checkTscId(scopeIdCandidate: string | null): boolean {
  return /^(7\d{3,9}|00[A-Z0-9]{8})$/.test(scopeIdCandidate || "");
}
