/**
 * This util makes it possible to handle path validations.
 * You can use a simplified syntax rather than raw RegExp. (See usage.)
 *
 * Previously we used the path-to-regexp package for this,
 * but it was removed due to security reasons, and that later versions lacked
 * the RegExp support that we depend on.
 */

/*
 *  Typed path variables.
 *  Note that "/" will be converted to "\\/"
 *  You should just use plain "/" in the strings.
 */
export const OPTIONAL_REMAINING_URL = "(?:/(.*))?";
export const REMAINING_URL = "(.*)";
export const OPTIONAL_SUBSCRIPTION_ID = "(/?\\w+)?";
export const OPTIONAl_CASE_ID = "(?:/(\\w+))?";
export const PRODUCT_ID = "([^/#\\?]+?)";
export const PRODUCT_CATEGORY = "([^/#\\?]+?)";
export const DELIVERY_ID = "(.+)";
export const ACCESS_PROFILE_ID = "(8\\d{8})";
export const TSC_ID = "(7\\d{3,9}|00[A-Z0-9]{8})";
export const CONFIGURATION_ID = "([\\d\\-]+)";
export const BASKET_ID = "([\\d\\-+]+)";
export const ORDER_ID = "([\\d\\-+]+)";
export const PERSON_ID = "(\\d+)";
export const NOTIFICATION_ID = "(\\d+)";
export const PRODUCT_CODE = "([^/#\\?]+?)";
export const BASKET_ID_ORDER = "([A-Za-z0-9]+)";
export const INVITE_ID = "(\\d+)";
export const NONCE = "(.+)";

export const SCOPE_ID = "(\\d+)"; // Should be reworked - too loose definition

export const MYBUSINESS_PATH_NO_SCOPE_ID = "^/foretag/mybusiness";
export const MYBUSINESS_PATH_SCOPE_ID = `^/foretag/mybusiness/${SCOPE_ID}`;
export const PATH_END = "[/#\\?]?$";

/**
 * Note: one simple replace rule applies for paths - replace "/" with "\\/" for RegExp conversion
 * @param path
 */
export const toPathWithoutScopeId = (path: string): string =>
  (MYBUSINESS_PATH_NO_SCOPE_ID + path + PATH_END).replace(/\//g, "\\/");

/**
 * Note: one simple replace rule applies for paths - replace "/" with "\\/" for RegExp conversion
 * @param path
 */
export const toPathWithScopeId = (path: string): string =>
  (MYBUSINESS_PATH_SCOPE_ID + path + PATH_END).replace(/\//g, "\\/");

export const toPathListWithoutScopeId = (paths: string[]): string[] => {
  return paths.map((path) => toPathWithoutScopeId(path));
};

export const toPathListWithScopeId = (paths: string[]): string[] => {
  return paths.map((path) => toPathWithScopeId(path));
};

/**
 * Can be used to create a custom path match expression (in /foretag/mybusiness context).
 * @param customMatchExpr the custom RegExp expression (with "/" instead of "\\/")
 */
export const toPathWithCustomMatcher = (customMatchExpr: string) =>
  ("^/foretag/mybusiness" + customMatchExpr + PATH_END).replace(/\//, "\\/");
