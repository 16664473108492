/*
CODE here on a temporary visit. Destination should be server/spa package when multipass is removed.
 */
import {
  ACCESS_PROFILE_ID,
  BASKET_ID,
  BASKET_ID_ORDER,
  CONFIGURATION_ID,
  DELIVERY_ID,
  INVITE_ID,
  NONCE,
  NOTIFICATION_ID,
  OPTIONAl_CASE_ID,
  OPTIONAL_REMAINING_URL,
  OPTIONAL_SUBSCRIPTION_ID,
  ORDER_ID,
  PERSON_ID,
  PRODUCT_CATEGORY,
  PRODUCT_CODE,
  PRODUCT_ID,
  REMAINING_URL,
  toPathListWithoutScopeId,
  toPathListWithScopeId,
  TSC_ID,
} from "./path";
import { isTestEnv } from "./env";

/**
 * Note these URL paths are used to validate the paths defined in SPAs index.html.
 *
 * They are also used to switch URL paths from TEST to PROD envs (deploy URLs).
 */
export const getNtseUrls = (): string[] => {
  /*
    NOTE: you can use RegExp syntax in the paths. Preferably use it in the variables.
    One simple rule applies - "/" will be converted to "\\/".

    You can test your paths on external RegExp tool sites.
    You can also write tests for your paths.

    /foretag/mybusiness/ is pre-pended to the paths with or without SCOPE_ID depending on the list you choose.
    Paths will also be appended with a general END of expression RegExp.
   */

  const PROD_PATHS_WITHOUT_SCOPE_ID: string[] = toPathListWithoutScopeId([
    "/federatedsso",

    // skaffa-mybusiness is also covered by the nonScopeId rule - included for completeness
    "/login", // old login - will redirect to start, but must be in list otherwise 404
    "/start",
    "/skaffa-mybusiness",
    "/skaffa-mybusiness/inte-inloggad",
    "/skaffa-mybusiness/inloggad",
    `/no-access${REMAINING_URL}`,
    `/planerat-underhall`,
    `/page-not-found${REMAINING_URL}`,
    `/something-went-wrong${REMAINING_URL}`,
    "", // /foretag/mybusiness
    "/minmobil",
    `/inbjudan/${INVITE_ID}/${NONCE}`,
    `/inbjudan/inloggad/${INVITE_ID}/${NONCE}`,
    `/inbjudan/inte-inloggad/${INVITE_ID}/${NONCE}`,
    "/om/villkor-mybusiness",
    "/((?!telia-admin|minmobil|logout|overlay|verifieraepostadress|getLastAccessTime|acceptInvite|begar-nytt-losenord-old|begar-nytt-losenord|Varfor-MB|varfor-mb-engelska|om|webbinarier|superuser-intro|superuser-guide|mobil-administration|komplettera|SRA|api|ajax|sso_sevone_error|sso_error|glomt-anvandarnamn|tpadmin-register|teliaFinanceRedirect|begar-nytt-losenord-datakom|inbjudan|feedback|register-new|registrera-new|register|[o]?\\d+).+)/(.*)?",
  ]);

  const PROD_PATHS_WITH_SCOPE_ID: string[] = toPathListWithScopeId([
    "", // /foretag/mybusiness/{SCOPE_ID}
    "/release-notes",
    "/fakturor/fakturor",
    `/fakturor/hantera-fakturering${REMAINING_URL}`,
    "/fakturor/dokument",
    "/bestall/formaner",
    "/bestall/produkter-tjanster",
    "/bestall/alla-bestallningar",
    "/bestall/lagda-bestallningar",
    `/bestall/oppna-mobilt-abonnemang${OPTIONAL_REMAINING_URL}`,
    `/hantera/oppna-mobilt-abonnemang${OPTIONAL_REMAINING_URL}`,
    `/bestall/oppna-iot-abonnemang${OPTIONAL_REMAINING_URL}`,
    `/bestall/touchpoint-plus-eftermarknad${OPTIONAL_REMAINING_URL}`,
    `/bestall/datanet${OPTIONAL_REMAINING_URL}`,
    "/bestall/touchpoint-plus-nybestallning",
    "/bestall/iot-sim-kort",
    "/bestall/fastighetsagare/hardvara",
    "/bestall/fastighetsagare/adressflytt",
    "/bestall/fastighetsagare/avtalsflytt",
    "/bestall/m365",
    "/support/arenden",
    `/support/arenden/(skapa|fraga)${OPTIONAL_SUBSCRIPTION_ID}`,
    `/support/arenden/detaljer${OPTIONAl_CASE_ID}`,
    "/support/kontakt",
    "/support/driftstatus",
    "/support/rapporter/(tillganglighet-av-tjanster|natverkstillganglighet|sla-rapporter)",
    `/hantera/produkter-tjanster/mobiltelefoni/${PRODUCT_ID}`,
    `/hantera/produkter-tjanster/mobiltbredband/${PRODUCT_ID}`,
    `/hantera/produkter-tjanster/${PRODUCT_CATEGORY}/detaljer/${PRODUCT_ID}${OPTIONAL_REMAINING_URL}`,
    `/hantera/produkter-tjanster/${PRODUCT_CATEGORY}/ny/${PRODUCT_ID}${OPTIONAL_REMAINING_URL}`,
    "/hantera/produkter-tjanster",
    "/hantera/produkter-tjanster/telefoni",
    "/hantera/produkter-tjanster/bredband",
    "/hantera/produkter-tjanster/vaxel",
    "/hantera/produkter-tjanster/ovrigt",
    "/hantera/overvakning-trafik",
    "/hantera/produkter-tjanster/datanet-vpn/leveranser",
    `/hantera/produkter-tjanster/datanet-vpn/leveranser/${DELIVERY_ID}`,
    "/hantera/produkter-tjanster/datanet-vpn/vpn-administration",
    "/hantera/produkter-tjanster/notifieringar",
    "/hantera/produkter-tjanster/notifieringar/ny",
    "/hantera/produkter-tjanster/notifieringar/anvandare",
    "/hantera/produkter-tjanster/notifieringar/anvandare/editera",
    "/hantera/anvandare",
    "/hantera/anvandare/bjudin",
    `/hantera/anvandare/editera/${ACCESS_PROFILE_ID}`,
    "/hantera/rapporter",
    `/bestall/${TSC_ID}/anpassa/${CONFIGURATION_ID}`,
    `/bestall/${TSC_ID}/kassa/${BASKET_ID}`,
    `/bestall/${TSC_ID}/bekraftelse/${ORDER_ID}`,
    "/installningar/foretag",
    `/installningar/minprofil${REMAINING_URL}`,
    "/not-authorized",
    "/sso/surfblaster-terminal",
    "/minmobil",
    "/hantera/produkter-tjanster/datanet-vpn/notifieringar",
    `/hantera/produkter-tjanster/datanet-vpn/notifieringar/${PERSON_ID}`,
    `/hantera/produkter-tjanster/datanet-vpn/notifieringar/${PERSON_ID}/ny-overvakning`,
    `/hantera/produkter-tjanster/datanet-vpn/notifieringar/${PERSON_ID}/${NOTIFICATION_ID}`,
    `/bestall/${TSC_ID}/new/mobilabonnemang`,
    `/bestall/${TSC_ID}/new/mobiltelefoner`,
    `/bestall/${TSC_ID}/new/tillbehor`,
    `/bestall/${TSC_ID}/new/bredband`,
    `/bestall/${TSC_ID}/new/mobilabonnemang/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/new/mobiltelefoner/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/new/tillbehor/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/mobilabonnemang`,
    `/bestall/${TSC_ID}/mobiltelefoner`,
    `/bestall/${TSC_ID}/tillbehor`,
    `/bestall/${TSC_ID}/bredband`,
    `/bestall/${TSC_ID}/emn`,
    `/bestall/${TSC_ID}/mobilabonnemang/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/mobiltelefoner/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/tillbehor/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/emn/produkt/${PRODUCT_CODE}`,
    `/bestall/${TSC_ID}/checka-ut`,
    `/bestall/${TSC_ID}/din-bestallning/${BASKET_ID_ORDER}`,
    "/sok",
  ]);

  const prodUrls = PROD_PATHS_WITHOUT_SCOPE_ID.concat(PROD_PATHS_WITH_SCOPE_ID);

  // Active NTSE test URLs
  const testUrls = toPathListWithScopeId([
    "/hantera/overvakning-larm",
    "/hantera/overvakning-larm/editera",
    "/hantera/overvakning-larm/skapa",
  ]);

  return isTestEnv() ? prodUrls.concat(testUrls) : prodUrls;
};

export const addNtseUrls = (urls: Array<string>): Array<RegExp> => {
  return urls.map((url) => new RegExp(url));
};

export const isUrlInNtse = (ntseUrlRegexes: Array<RegExp>, pathname: string): boolean => {
  return ntseUrlRegexes.some((urlRegex) => urlRegex.test(pathname));
};
